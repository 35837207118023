import axios from '@/common/axios'
import qs from 'qs'

export function addAssetPurchase (data) {
  return axios({
    method: 'post',
    url: '/universal/asset/add',
    data: qs.stringify(data)
  })
}

export function deleteAssetPurchase (id) {
  return axios({
    method: 'delete',
    url: '/universal/asset/delete/' + id
  })
}

export function updateAssetPurchase (data) {
  return axios({
    method: 'put',
    url: '/universal/asset/update',
    data: qs.stringify(data)
  })
}

export function selectAssetPurchaseInfo (id) {
  return axios({
    method: 'get',
    url: '/universal/asset/info/' + id
  })
}

export function selectAssetPurchaseList (query) {
  return axios({
    method: 'get',
    url: '/universal/asset/list',
    params: query
  })
}
