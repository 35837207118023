<template>
  <div id="assetPurchase">
    <el-dialog
      :title="assetPurchaseFormTitle"
      width="680px"
      :visible.sync="assetPurchaseDialogVisible"
      :close-on-click-modal="false"
      @close="assetPurchaseDialogClose"
    >
      <el-form
        ref="assetPurchaseFormRef"
        :model="assetPurchaseForm"
        :rules="assetPurchaseFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="名称" prop="name">
              <el-input v-model="assetPurchaseForm.name" placeholder="请输入名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数量" prop="quantity">
              <el-input v-model="assetPurchaseForm.quantity" placeholder="请输入数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="申请部门" prop="dept">
              <el-input v-model="assetPurchaseForm.dept" placeholder="请输入申请部门" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="申请人" prop="applicant">
              <el-input v-model="assetPurchaseForm.applicant" placeholder="请输入申请人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="申请时间" prop="applicationTime">
              <el-date-picker v-model="assetPurchaseForm.applicationTime" placeholder="请选择申请时间" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="要求完成日期" prop="completionDate">
              <el-date-picker v-model="assetPurchaseForm.completionDate" placeholder="请选择要求完成日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用途及要求" prop="purposeRequirement">
              <el-input
                v-model="assetPurchaseForm.purposeRequirement"
                placeholder="请输入用途及要求"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="附件">
              <el-scrollbar style="height: 95.2px">
                <el-upload
                  ref="uploadRef"
                  action=""
                  :http-request="handleHttp"
                  :on-remove="handleRemove"
                  :file-list="fileList"
                  multiple
                >
                  <el-button size="small" type="primary">
                    点击上传
                  </el-button>
                </el-upload>
              </el-scrollbar>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="部门主管审批" prop="deptHeadApproval">
              <el-radio-group v-model="assetPurchaseForm.deptHeadApproval">
                <el-radio :label="1">
                  同意
                </el-radio>
                <el-radio :label="2">
                  不同意
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="总经理审批" prop="generalManagerApproval">
              <el-radio-group v-model="assetPurchaseForm.generalManagerApproval">
                <el-radio :label="1">
                  同意
                </el-radio>
                <el-radio :label="2">
                  不同意
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="assetPurchaseForm.remarks"
                placeholder="请输入备注"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="assetPurchaseDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="assetPurchaseFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="名称">
        <el-input v-model="searchForm.name" placeholder="请输入名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="assetPurchasePage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="name" label="名称" />
      <el-table-column prop="quantity" label="数量" />
      <el-table-column prop="dept" label="申请部门" />
      <el-table-column prop="applicant" label="申请人" />
      <el-table-column label="申请时间">
        <template slot-scope="scope">
          <span v-if="scope.row.applicationTime">{{ scope.row.applicationTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="要求完成日期">
        <template slot-scope="scope">
          <span v-if="scope.row.completionDate">{{ scope.row.completionDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="purposeRequirement" label="用途及要求" />
      <el-table-column label="部门主管审批">
        <template slot-scope="scope">
          <span v-if="scope.row.deptHeadApproval === 1">同意</span>
          <span v-if="scope.row.deptHeadApproval === 2">不同意</span>
        </template>
      </el-table-column>
      <el-table-column label="总经理审批">
        <template slot-scope="scope">
          <span v-if="scope.row.generalManagerApproval === 1">同意</span>
          <span v-if="scope.row.generalManagerApproval === 2">不同意</span>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="assetPurchasePage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addAssetPurchase, deleteAssetPurchase, updateAssetPurchase, selectAssetPurchaseInfo, selectAssetPurchaseList } from '@/api/universal/assetPurchase'
import { upload } from '@/api/main/file'

export default {
  data () {
    return {
      assetPurchaseDialogVisible: false,
      assetPurchaseFormTitle: '',
      assetPurchaseForm: {
        id: '',
        name: '',
        quantity: '',
        dept: '',
        applicant: '',
        applicationTime: '',
        completionDate: '',
        purposeRequirement: '',
        annexUrl: '',
        annexName: '',
        deptHeadApproval: '',
        generalManagerApproval: '',
        remarks: ''
      },
      assetPurchaseFormRules: {
        name: [{ required: true, message: '名称不能为空', trigger: ['blur', 'change']}]
      },
      assetPurchasePage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        name: ''
      },
      fileList: []
    }
  },
  created () {
    selectAssetPurchaseList(this.searchForm).then(res => {
      this.assetPurchasePage = res
    })
  },
  methods: {
    assetPurchaseDialogClose () {
      this.$refs.assetPurchaseFormRef.resetFields()
      this.$refs.uploadRef.clearFiles()
      this.fileList = []
    },
    assetPurchaseFormSubmit () {
      if (this.assetPurchaseFormTitle === '资产购买申请单详情') {
        this.assetPurchaseDialogVisible = false
        return
      }
      this.$refs.assetPurchaseFormRef.validate(async valid => {
        if (valid) {
          this.assetPurchaseForm.annexUrl = this.fileList.map(file => file.path).join(',')
          this.assetPurchaseForm.annexName = this.fileList.map(file => file.name).join(',')
          if (this.assetPurchaseFormTitle === '新增资产购买申请单') {
            await addAssetPurchase(this.assetPurchaseForm)
          } else if (this.assetPurchaseFormTitle === '修改资产购买申请单') {
            await updateAssetPurchase(this.assetPurchaseForm)
          }
          this.assetPurchasePage = await selectAssetPurchaseList(this.searchForm)
          this.assetPurchaseDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.assetPurchaseFormTitle = '新增资产购买申请单'
      this.assetPurchaseDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteAssetPurchase(row.id)
        if (this.assetPurchasePage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.assetPurchasePage = await selectAssetPurchaseList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.assetPurchaseFormTitle = '修改资产购买申请单'
      this.assetPurchaseDialogVisible = true
      this.selectAssetPurchaseInfoById(row.id)
    },
    handleInfo (index, row) {
      this.assetPurchaseFormTitle = '资产购买申请单详情'
      this.assetPurchaseDialogVisible = true
      this.selectAssetPurchaseInfoById(row.id)
    },
    selectAssetPurchaseInfoById (id) {
      selectAssetPurchaseInfo(id).then(res => {
        this.assetPurchaseForm.id = res.id
        this.assetPurchaseForm.name = res.name
        this.assetPurchaseForm.quantity = res.quantity
        this.assetPurchaseForm.dept = res.dept
        this.assetPurchaseForm.applicant = res.applicant
        this.assetPurchaseForm.applicationTime = res.applicationTime
        this.assetPurchaseForm.completionDate = res.completionDate
        this.assetPurchaseForm.purposeRequirement = res.purposeRequirement
        if (res.annexUrl) {
          const annexUrl = res.annexUrl.split(',')
          const annexName = res.annexName.split(',')
          this.fileList = []
          for (let i = 0; i < annexUrl.length; i++) {
            this.fileList.push({
              name: annexName[i],
              path: annexUrl[i]
            })
          }
        }
        this.assetPurchaseForm.deptHeadApproval = res.deptHeadApproval
        this.assetPurchaseForm.generalManagerApproval = res.generalManagerApproval
        this.assetPurchaseForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectAssetPurchaseList(this.searchForm).then(res => {
        this.assetPurchasePage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectAssetPurchaseList(this.searchForm).then(res => {
        this.assetPurchasePage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectAssetPurchaseList(this.searchForm).then(res => {
        this.assetPurchasePage = res
      })
    },
    handleHttp (file) {
      const data = new FormData()
      data.append('file', file.file)
      upload(data).then(res => {
        this.fileList.push({
          name: file.file.name,
          path: res,
          uid: file.file.uid
        })
      })
    },
    handleRemove (file, fileList) {
      this.fileList = fileList
    }
  }
}
</script>

<style>
#assetPurchase .el-scrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>
